import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { URL_API } from '../../../Components/Data';

const GetFactsheet = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [textSize, setTextSize] = useState('text-sm'); // Default text size

    useEffect(() => {
        const fetchFactsheet = async () => {
            setIsLoading(true);
            try {
                let response = await fetch(`${URL_API}/api/v1/cases/factsheet/caseentry/${id}`);
                if (!response.ok) {
                    throw new Error('Something went wrong!');
                }
                const data = await response.json();
                setData(data);
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false);
            }
        };
        fetchFactsheet();
    }, [id]);

    const increaseTextSize = () => {
        setTextSize('text-lg'); // Increase text size
    };

    const decreaseTextSize = () => {
        setTextSize('text-base'); // Decrease text size
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }


    return (
        <>


            <h1 className={`text-2xl font-bold text-gray-900 my-4 text-center bg-gray-200 p-3 ${textSize}`}>
                Fact Sheet
            </h1>
            {data.length > 0 ? (
                data.map((item, index) => (
                    <div key={index} className={`p-4 mb-6 bg-white shadow-md rounded-lg ${textSize}`}> {/* Use a more unique key in a real-world scenario */}
                        <div className="space-y-6">
                            <div><span className="font-semibold">Facts:</span> {item.facts}</div>
                            <div><span className="font-semibold">Case Instituted:</span> {item.caseinstituted}</div>
                            <div><span className="font-semibold">Hearings:</span> {item.hearings}</div>
                            <div><span className="font-semibold">Nature of Case:</span> {item.Natureofcase}</div>
                            <div><span className="font-semibold">Defendants:</span> {item.defendants}</div>
                            <div><span className="font-semibold">Last Date of Hearing:</span> {item.lastdateofhearing}</div>
                        </div>
                    </div>
                ))
            ) : (
                <p className={`text-center mt-5 ${textSize}`}>No factsheet found</p>
            )}

            <div className="text-center my-4">
                <button onClick={increaseTextSize} className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded mr-2">
                    Increase Text
                </button>
                <button onClick={decreaseTextSize} className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded">
                    Decrease Text
                </button>
            </div>
        </>
    );
};

export default GetFactsheet;
