import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { URL_API } from "../../../Components/Data";
import { useDispatch, useSelector } from "react-redux";
import { Getallusers } from "../../../Actions/Actions";

const Allusers = () => {
    const dispatch = useDispatch();
    const allusers = useSelector((state) => state.case.allusers);

    useEffect(() => {
        dispatch(Getallusers());
    }, [dispatch, allusers]);
    

    const handleApprovalChange = async (userid, isUserApproved) => {
        try {
            const response = await axios.post(
                `${URL_API}/api/v1/admin/approved-user-to-edit-case/${userid}`,
                { isUserApproved }
            );

            alert(response.data.message);
        } catch (error) {
            console.error("Error updating approval status:", error);
        }
    };

    const Deleteuser = async (userid) => {
        if (window.confirm("Are you sure you want to delete this user?")) {
            try {
                const response = await fetch(`${URL_API}/api/v1/users/deleteuser/${userid}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
    
                if (response.ok) {
                    const data = await response.json();
                    console.log("User deleted successfully:", data);
                    // Optionally, update the UI or notify the user about the successful deletion
                } else {
                    const errorData = await response.json();
                    console.error("Failed to delete user:", errorData.message);
                    // Optionally, handle the error or notify the user
                }
            } catch (error) {
                console.error("An error occurred while deleting the user:", error);
                // Optionally, handle the error or notify the user
            }
        } else {
            console.log("User deletion canceled.");
            // Optionally, handle the cancellation or notify the user
        }
    };
    


    return (
        <main className="w-full">
            <div className="container mx-auto mt-5 px-4 sm:px-6 lg:px-8">
                <div className="shadow-lg mt-2 overflow-x-auto">
                    <table className="min-w-full leading-normal text-center">
                        <thead>
                            <tr className="bg-gray-100 border-b border-gray-200">
                                <th className="px-5 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    #
                                </th>
                                <th className="px-5 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Profile
                                </th>
                                <th className="px-5 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Name
                                </th>
                                <th className="px-5 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Email
                                </th>
                                <th className="px-5 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Role
                                </th>
                                <th className="px-5 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Status
                                </th>
                                <th className="px-5 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {allusers.map((item, index) => (
                                <tr key={item._id} className="hover:bg-gray-100">
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        {index + 1}
                                    </td>
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        <img
                                            className="rounded-full h-10 w-10 mx-auto"
                                            src={`${URL_API}/${item.profilePicture?.replace(
                                                /\\/g,
                                                "/"
                                            )}`}
                                            alt="Profile"
                                        />
                                    </td>
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        {item.name}
                                    </td>
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        {item.email}
                                    </td>
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        {item.role}
                                    </td>
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        <select
                                            className="form-select block w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            value={item.isUserApproved}
                                            onChange={(e) =>
                                                handleApprovalChange(item._id, e.target.value)
                                            }
                                        >
                                            <option value="true">Approved</option>
                                            <option value="false">Not Approved</option>
                                        </select>
                                    </td>
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        <Link
                                            to={`/useredit/${item._id}`}
                                            className="text-blue-600 hover:text-blue-900"
                                        >
                                            Edit
                                        </Link>
                                    </td>
                                    <td className="px-5 py-4 border-b border-gray-200 text-sm">
                                        <Link
                                            onClick={() => Deleteuser(item._id)}
                                            className="text-blue-600 hover:text-blue-900"
                                        >
                                            Delete
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </main>
    );
};

export default Allusers;
