import React, { useEffect, useState } from 'react';
import { URL_API } from '../../../Components/Data';

const Clientview = () => {
    const [casesrole, setCasesrole] = useState([]);
    const [selectedCase, setSelectedCase] = useState(null); // State to store selected case details
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const user = sessionStorage.getItem('user');
    const token = user ? JSON.parse(user).token : null;

    useEffect(() => {
        const fetchProtectedData = async () => {
            try {
                const response = await fetch(`${URL_API}/api/v1/users/getusersonrole`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Request failed');
                }

                const data = await response.json();
                setCasesrole(data);
            } catch (error) {
                console.error('Error fetching protected data:', error);
            }
        };
        fetchProtectedData();
    }, [token]);

    // Function to handle when a table row is clicked
    const handleRowClick = (item) => {
        setSelectedCase(item);
        setIsModalOpen(true); // Open the modal
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="container mx-auto my-8">
            <h1 className="text-2xl font-semibold text-center text-gray-800 mb-6">CASES</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Suit No
                            </th>
                            <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Title
                            </th>
                            <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Nature of Suit
                            </th>
                            <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Prev hearing
                            </th>
                            <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Next hearing
                            </th>
                           
                            <th className="px-5 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            casesrole.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-100 cursor-pointer" onClick={() => handleRowClick(item)}>
                                    <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div className="text-gray-900 whitespace-no-wrap">{item.Suitno}</div>
                                    </td>
                                    <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div className="text-gray-900 whitespace-no-wrap">{item.title}</div>
                                    </td>
                                    <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div className="text-gray-900 whitespace-no-wrap">{item.NatureofSuit}</div>
                                    </td>
                                    <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div className="text-gray-900 whitespace-no-wrap">{item.prevhearing}</div>
                                    </td>
                                    <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div className="text-gray-900 whitespace-no-wrap">{item.nexthearing}</div>
                                    </td>
                                   
                                    <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <button onClick={() => handleRowClick(item)} className="text-blue-500">View Details</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {/* Modal */}
            {isModalOpen && selectedCase && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg p-8 w-full max-w-2xl">
                        <h2 className="text-2xl font-bold mb-4">Case Details</h2>
                        <div className="mb-4"><strong>Suit No:</strong> {selectedCase.Suitno}</div>
                        <div className="mb-4"><strong>Title:</strong> {selectedCase.title}</div>
                        <div className="mb-4"><strong>Court:</strong> {selectedCase.court}</div>
                        <div className="mb-4"><strong>Lawyer:</strong> {selectedCase.lawyer}</div>
                        <div className="mb-4"><strong>Client Name:</strong> {selectedCase.Clientname}</div>
                        <div className="mb-4"><strong>Nature of Suit:</strong> {selectedCase.NatureofSuit}</div>
                        <div className="mb-4"><strong>Previous Hearing:</strong> {selectedCase.prevhearing}</div>
                        <div className="mb-4"><strong>Next Hearing:</strong> {selectedCase.nexthearing}</div>
                        <div className="mb-4"><strong>Number of Defendants:</strong> {selectedCase.numberOfDefendants}</div>
                        <div className="mb-4"><strong>POA Filing Date (Plaintiff):</strong> {selectedCase.poaFilingDatePlaintiff}</div>
                        <div className="mb-4"><strong>POA Filing Date (Defendant):</strong> {selectedCase.poaFilingDateDefendant}</div>
                        <div className="mb-4"><strong>Current Status:</strong> {selectedCase.CurrentStatus}</div>
                        <div className="flex justify-end">
                            <button className="bg-red-500 text-white px-4 py-2 rounded" onClick={closeModal}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Clientview;
