import React from 'react'

const Team = () => {
    return (
        <>
            {/* Container for demo purpose */}
            <div className="container my-24 mx-auto md:px-6">
                {/* Section: Design Block */}
                <section className="mb-32 text-center">
                    <h2 className="mb-12 text-3xl font-bold">
                        Meet the <u className="text-primary dark:text-primary-400">team</u>
                    </h2>

                    <div className="lg:gap-xl-12 grid gap-x-6 md:grid-cols-3 xl:grid-cols-4">
                        <div className="mb-12">
                            <img src="https://static.vecteezy.com/system/resources/previews/022/123/337/non_2x/user-icon-profile-icon-account-icon-login-sign-line-vector.jpg" className="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20" alt style={{ maxWidth: 100 }} />
                            <p className="mb-2 font-bold">Name</p>
                            <p className="text-neutral-500 dark:text-neutral-300">lawyer</p>
                        </div>

                        <div className="mb-12">
                            <img src="https://static.vecteezy.com/system/resources/previews/022/123/337/non_2x/user-icon-profile-icon-account-icon-login-sign-line-vector.jpg" className="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20" alt style={{ maxWidth: 100 }} />
                            <p className="mb-2 font-bold">Name</p>
                            <p className="text-neutral-500 dark:text-neutral-300">lawyer</p>
                        </div>

                        <div className="mb-12">
                            <img src="https://static.vecteezy.com/system/resources/previews/022/123/337/non_2x/user-icon-profile-icon-account-icon-login-sign-line-vector.jpg" className="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20" alt style={{ maxWidth: 100 }} />
                            <p className="mb-2 font-bold">Name</p>
                            <p className="text-neutral-500 dark:text-neutral-300">
                                lawyer
                            </p>
                        </div>

                        <div className="mb-12">
                            <img src="https://static.vecteezy.com/system/resources/previews/022/123/337/non_2x/user-icon-profile-icon-account-icon-login-sign-line-vector.jpg" className="mx-auto mb-4 rounded-full shadow-lg dark:shadow-black/20" alt style={{ maxWidth: 100 }} />
                            <p className="mb-2 font-bold">Name</p>
                            <p className="text-neutral-500 dark:text-neutral-300">lawyer</p>
                        </div>




                    </div>
                </section>
                {/* Section: Design Block */}
            </div>
            {/* Container for demo purpose */}

        </>
    )
}

export default Team
