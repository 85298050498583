import React from 'react'
import Team from './Team'

const Homepage = () => {
    return (
        <>
            <section>
                <section class="bg-center bg-no-repeat bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/conference.jpg')] bg-gray-700 bg-blend-multiply">
                    <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
                        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">Zia & Shah Legal Consultants</h1>
                        <p class="mb-8 text-lg text-justify font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">Zia & Shah envisions to ensure access to justice by providing highly professional and scholastic legal services with dedication, responsibility and integrity. The Firm also aims at providing pro bono legal services to the society in general so that the rights enjoyed by citizens in common are not impaired. The Firm intensely aims at contributing towards the development of law and the process of administration of justice by resorting to meaningful measures for the promotion of legal education and for raising awareness among the masses as to their rights and obligations.</p>
                        <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                            <a href="#" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
                                Book Free Consultation
                                <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                </svg>
                            </a>
                            {/* <a href="#" class="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">
                  Learn more
                </a> */}
                        </div>
                    </div>
                </section>

            </section>
            <section>
                <Team />
            </section>
        </>


    )
}

export default Homepage