import React, { useEffect, useState } from 'react'
import { gsap } from "gsap";
import { GoLaw } from "react-icons/go";
import CountUp from 'react-countup';
import { URL_API } from '../Data';
import { useDispatch, useSelector } from 'react-redux';
import { pendingrequests } from '../../Actions/Actions';
import { fetchTodayHearings } from '../../Actions/Actions';
import { Link } from 'react-router-dom';
import { Getallusers } from '../../Actions/Actions';
import { CiMedicalCase } from "react-icons/ci";


const DashboardCards = () => {

    const [data, setData] = useState([])
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchlength = async () => {
            try {
                let response = await fetch(`${URL_API}/api/v1/cases/all-cases-length`)
                const data = await response.json()
                if (response.ok) {
                    setData(data)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchlength()
    }, [])


    const pendingrequest = useSelector((state) => state.case.pendingrequest);
    const hearings = useSelector(state => state.case.todayCases);
    const allusers = useSelector(state => state.case.allusers);


    useEffect(() => {
        dispatch(pendingrequests()); // Dispatch the action to fetch roles
        dispatch(fetchTodayHearings());
        dispatch(Getallusers());
    }, [dispatch]);





    useEffect(() => {
        gsap.from(".admin-dashboard-title", { duration: 1, autoAlpha: 0, y: -30 });

        const tl = gsap.timeline();
        tl.to(".dashboard-card", {
            duration: 0.5,
            autoAlpha: 1,
            scale: 1.05,
            stagger: 0.2,
            ease: "power2.inOut"
        });
    }, []);


    return (
        <>
            <div className='grid grid-cols-1 gap-2 md:grid-cols-4 md:gap-4 admin-dashboard-title'>
                {/* 1 */}
                <div class=" dashboard-card opacity-0 scale-95  transform hover:-translate-y-1 hover:scale-105 rounded-sm border border-stroke bg-blue-50 py-4 px-6 shadow-md dark:border-strokedark dark:bg-boxdark transition duration-300 ease-in-out hover:shadow-lg">
                    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                        <CiMedicalCase className='text-4xl' />
                    </div>

                    <div class="mt-3">
                        <div class='ml-2'>
                            <h4 class="text-lg md:text-xl font-bold text-black dark:text-white">
                                <CountUp end={data ? data.length : 0} duration={2} delay={0.5} start={1} />
                            </h4>
                            <span class="text-sm md:text-base font-medium">Total Cases</span>
                        </div>
                    </div>
                </div>

                {/* 2 */}
                <div class="dashboard-card opacity-0 scale-95  transform hover:-translate-y-1 hover:scale-105 rounded-sm border border-stroke bg-yellow-50 py-4 px-6 shadow-md dark:border-strokedark dark:bg-boxdark transition duration-300 ease-in-out hover:shadow-lg">
                    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                        <CiMedicalCase className='text-4xl' />
                    </div>

                    <div class="mt-3">
                        <div class='ml-2'>
                            <h4 class="text-lg md:text-xl font-bold text-black dark:text-white">
                                <div>{Array.isArray(hearings) ? hearings.length : '0'}</div>
                            </h4>
                            <span class="text-sm md:text-base font-medium">Today's Cases</span>
                        </div>
                    </div>
                </div>

                {/* 3 */}
                <Link to='/pending-requests'>
                    <div class="dashboard-card opacity-0 scale-95  transform hover:-translate-y-1 hover:scale-105 rounded-sm border border-stroke bg-green-50 py-4 px-6 shadow-md dark:border-strokedark dark:bg-boxdark transition duration-300 ease-in-out hover:shadow-lg">
                        <div class="flex h-10 w-10 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                            <CiMedicalCase className='text-4xl' />
                        </div>

                        <div class="mt-3">
                            <div class='ml-2'>
                                <h4 class="text-lg md:text-xl font-bold text-black dark:text-white">
                                    <CountUp end={pendingrequest ? pendingrequest.length : 0} duration={2} delay={0.5} start={1} />

                                </h4>
                                <span class="text-sm md:text-base font-medium">Pending Approvals</span>
                            </div>
                        </div>
                    </div>
                </Link>


                {/* 4 */}
                <div class="dashboard-card opacity-0 scale-95  transform hover:-translate-y-1 hover:scale-105 rounded-sm border border-stroke bg-red-50 py-4 px-6 shadow-md dark:border-strokedark dark:bg-boxdark transition duration-300 ease-in-out hover:shadow-lg">
                    <div class="flex h-10 w-10 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4">
                        <CiMedicalCase className='text-4xl' />
                    </div>

                    <div class="mt-3">
                        <div class='ml-2'>
                            <h4 class="text-lg md:text-xl font-bold text-black dark:text-white">
                                <CountUp end={allusers ? allusers.length : 0} duration={2} delay={0.5} start={1} />

                            </h4>
                            <span class="text-sm md:text-base font-medium">Total Users</span>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}

export default DashboardCards
