import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAllCases } from "../../../Actions/Actions";
import { useSelector } from "react-redux";
import { deleteEntry } from "../../../Actions/Actions";
import { ThreeDots } from 'react-loader-spinner'
import { courts } from "../../../Components/Data";
import DeleteModal from "../../../Components/Portal/Delemodal";

function Cases() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const { cases, isLoading, totalPages } = useSelector((state) => ({
    cases: state.case.cases,
    isLoading: state.case.isLoading,
    totalPages: state.case.totalPages,
  }));
  

  // Debouncing effect for search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // Delay for 500 ms

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCases(debouncedSearchTerm, currentPage));
  }, [debouncedSearchTerm, currentPage, dispatch ]);

  const handleDeleteEntry = (id) => {
    setSelectedId(id); // Set the ID of the item to be deleted
    setIsModalOpen(true);
  };


  const handleConfirmDelete = (id) => {
    dispatch(deleteEntry(id));
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };










  // Render pagination controls
  const renderPagination = () => (
    <div className="pagination flex justify-center items-center space-x-2 my-4">
      <button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(1)}
        className={`px-4 py-2 text-sm ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-500 hover:text-white'}`}
      >
        First
      </button>
      <button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
        className={`px-4 py-2 text-sm ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-500 hover:text-white'}`}
      >
        Prev
      </button>
      {[...Array(totalPages).keys()].map(number => (
        <button
          key={number}
          onClick={() => setCurrentPage(number + 1)}
          className={`px-4 py-2 text-sm ${currentPage === number + 1 ? 'bg-blue-500 text-white' : 'hover:bg-blue-500 hover:text-white'}`}
        >
          {number + 1}
        </button>
      ))}
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
        className={`px-4 py-2 text-sm ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-500 hover:text-white'}`}
      >
        Next
      </button>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage(totalPages)}
        className={`px-4 py-2 text-sm ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-500 hover:text-white'}`}
      >
        Last
      </button>
    </div>
  );





  return (
    <>
      <div>

        <div className="grid grid-cols-2 space-x-2 p-3">
          <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Suitno, title ..." required
              onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} />
            <button type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => setSearchTerm("")}>Clear</button>
          </div>


          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          >
            <option selected>Search Court</option>
            {courts.map((court) => (
              <option key={court.id} value={court.id}>
                {court.name}
              </option>
            ))}
          </select>
        </div>

        {isLoading ? (
          // <div className="loader-container flex justify-center items-center h-64 ">
          //   <div className="loader"></div>
          // </div>

          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="blue"
            ariaLabel="loading"
            wrapperStyle={{
              display: 'flex', // Use flex display
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              height: '100vh' // Make the container full viewport height
            }}
            wrapperClass="your-wrapper-class"
          />


        ) : (
          <div className="overflow-x-auto">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th class="px-6 py-3"> # </th>
                  <th class="px-6 py-3"> Title </th>
                  <th class="px-6 py-3"> NATURE OF SUIT </th>
                  <th class="px-6 py-3"> ADDED BY </th>
                  <th class="px-6 py-3 whitespace-nowrap">HISTORY </th>
                  <th class="px-6 py-3 whitespace-nowrap">FACT SHEET</th>
                  <th class="px-6 py-3 whitespace-nowrap"> DELETE </th>
                  <th class="px-6 py-3 whitespace-nowrap"> EDIT </th>
                </tr>
              </thead>
              <tbody>
                {cases.length > 0 ? (
                  cases.map((caseItem, index) => (
                    <tr key={caseItem._id}>
                      <td className="px-6 py-4">{index + 1}</td>
                      <td className="px-6 py-4">{caseItem.title}</td>
                      <td className="px-6 py-4">
                        {caseItem.NatureofSuit ? caseItem.NatureofSuit : caseItem.nature}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {caseItem.addedBy ? caseItem.addedBy : "Super USer"}
                      </td>
                      <td className="px-6 py-4">
                        <Link
                          to={`/case-details/${caseItem._id}`}
                          className="text-blue-600 hover:underline"
                        >
                          History
                        </Link>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <Link to={`/fact-sheet/${caseItem._id}`} className="text-yellow-500">
                          Fact Sheet</Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="text-red-500" onClick={() => handleDeleteEntry(caseItem._id)}>Delete</button>
                      </td>
                      <DeleteModal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        onDelete={() => handleConfirmDelete(selectedId)} // Use the selectedId here
                      />
                      <td className="px-6 py-4 whitespace-nowrap">
                        <Link className="text-green-500"
                          to={`/edit-case/${caseItem._id}`}>
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="px-6 py-4 text-center">
                      No cases found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="">
              {renderPagination()}
            </div>
          </div>
        )}

      </div>

    </>
  );
}

export default Cases;
