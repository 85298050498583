// store/index.js
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import caseReducer from '../Reducers/Casereducer'; // We will create this file in the next step

const rootReducer = combineReducers({
  // Add your reducers here
  case: caseReducer,
  roles: caseReducer,




});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
