import React from "react";
import { Route, Routes } from "react-router-dom";
import Loginpage from "../../Screens/Portal/Auth/Loginpage";
import ProtectedRoute from "../ProtectedRoutes";
import Allcases from "../../Screens/Portal/Cases/Allcases";
import Addcase from "../../Screens/Portal/Cases/Addcase";
import Dashboard from "../../Screens/Portal/Dashboard/Dashboard";
import Historypage from '../../Screens/Portal/Cases/History'
import Editcase from "../../Screens/Portal/Cases/Editcase";
import Clientview from "../../Screens/Portal/Clients/Clientview";
import Errorpage from "../../Screens/Error/Errorpage";
import Adduser from "../../Screens/Portal/Users/Adduser";
import GetFactsheet from "../../Screens/Portal/Factsheet/Getfactsheet";
import Allusers from "../../Screens/Portal/Users/Allusers";
import Addroles from "../../Screens/Settings/Addrole";
import Edituser from "../../Screens/Portal/Users/Edituser";
import Pendingrequest from "../../Screens/Portal/Admin/Pendingrequest";
import Todaycases from "../../Screens/Portal/Cases/Todaycases";

const Routing = () => {

  return (
    <>
      <>


        <Routes>


          <Route path='/dashboard' element={<ProtectedRoute component={Dashboard} allowedRoles={["admin"]} />} />
          {/* Cases */}
          <Route path="/add-case" element={<Addcase />} />
          <Route path="/all-case" element={<Allcases />} />
          <Route path="/today-cases" element={<Todaycases />} />

          {/* Auth */}
          <Route path="/register" element={<Adduser />} />

          {/* Users */}
          <Route path="/users" element={<Allusers />} />
          <Route path="/useredit/:id" element={<Edituser />} />
          <Route path="/case-details/:id" element={<Historypage />} />
          <Route path="/fact-sheet/:id" element={<GetFactsheet />} />
          <Route path="/edit-case/:id" element={<Editcase />} />
          <Route path="/client-view" element={<Clientview />} />
          <Route path="/addrole" element={<Addroles />} />

          {/* Admin */}
          <Route path="/pending-requests" element={<Pendingrequest />} />


          {/* Error */}
          <Route path="/*" element={<Errorpage />} />


        </Routes>
      </>
    </>
  );
};

export default Routing;
