import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { URL_API } from "../../../Components/Data";
import { fetchRoles } from "../../../Actions/Actions";
import { useDispatch, useSelector } from "react-redux";

const Adduser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  // const [roles, setRoles] = useState([])

  const Registerhandler = async (e) => {
    e.preventDefault();

    let formdata = new FormData();

    formdata.append("profilePicture", profilePicture);
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("role", role);

    let response = await fetch(`${URL_API}/api/v1/auth/registration`, {
      method: "post",
      body: formdata, // use formData object
    });
    response = await response.json();
    alert(response.message);
  };

  const dispatch = useDispatch();
  const roles = useSelector((state) => state.case.roles);

  useEffect(() => {
    dispatch(fetchRoles()); // Dispatch the action to fetch roles
  }, [dispatch]);

 

  return (
    <>
      <div className="rounded-md border border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800">
        <div className="flex flex-wrap items-center">
          <div className="hidden w-full lg:block lg:w-1/2">
            <div className="py-10 px-12 text-center">
              <Link className="mb-6 inline-block" to="/">
                <img
                  className="dark:hidden"
                  src="https://homebusinessmag.com/wp-content/uploads/2019/07/Depositphotos_41649239_s-2019-e1562353249920.jpg"
                  alt="Logo"
                />
              </Link>
              <h1 className="text-3xl font-semibold text-gray-800 dark:text-white">
                Zia & Shah | Associates - Legal Consultants
              </h1>
              <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
                Providing Legal Assistance in all sorts of legal matters
              </p>
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <div className="w-full p-8">
              <h2 className="mb-8 text-2xl font-bold text-gray-800 dark:text-white">
                User Registration
              </h2>

              <form>
                <div className="mb-6">
                  <label
                    htmlFor="name"
                    className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your full name"
                    className="w-full rounded-md border border-gray-300 bg-white py-3 px-4 text-sm text-gray-700 shadow-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:focus:border-indigo-500"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    className="w-full rounded-md border border-gray-300 bg-white py-3 px-4 text-sm text-gray-700 shadow-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:focus:border-indigo-500"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="password"
                    className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter your password"
                    className="w-full rounded-md border border-gray-300 bg-white py-3 px-4 text-sm text-gray-700 shadow-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:focus:border-indigo-500"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="client-name"
                    className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Client Name
                  </label>
                  <select
                    className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    id="formFile"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option>--select--</option>
                    {roles.map((role, index) => (
                      <option key={index} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="image"
                    className="mb-2 block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    id="image"
                    className="w-full rounded-md border border-gray-300 bg-white py-3 px-4 text-sm text-gray-700 shadow-sm focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 dark:focus:border-indigo-500"
                    onChange={(e) => setProfilePicture(e.target.files[0])}
                  />
                </div>

                <div className="mb-6">
                  <button
                    type="button"
                    className="w-full rounded-md bg-indigo-600 py-3 px-4 text-sm font-medium text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-indigo-500 dark:hover:bg-indigo-600"
                    onClick={Registerhandler}
                  >
                    Create account
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adduser;
