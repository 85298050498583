import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHistory } from "../../../Actions/Actions";
import { useParams } from "react-router-dom";
import { updateHistory } from "../../../Actions/Actions";
import { deleteHistory } from "../../../Actions/Actions";

const History = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { history: historyData, title, error: errorMessage, isLoading } = useSelector((state) => state.case.data || { history: [], title: '' });


    // const historyData = data?.history || []; // Safely access history array
    // const title = data?.title || ""; // Safely access title
    const user = localStorage.getItem('user')
    const token = user ? JSON.parse(user).token : null


    const [file, setFile] = useState(null)
    const [date, setDate] = useState('');
    const [proceedings, setProceedings] = useState('');
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [errorMessagenew, setErrorMessagenew] = useState('');
    const [isVisible, setIsVisible] = useState(false); // initial state is false, so the div is hidden




    const itemsPerPage = 5; // Define how many items per page
    // Pagination logic
    const lastItemIndex = currentPage * itemsPerPage;
    const firstItemIndex = lastItemIndex - itemsPerPage;
    const currentItems = filteredHistory.slice(firstItemIndex, lastItemIndex);
    const totalPages = Math.ceil(filteredHistory.length / itemsPerPage);

    const changePage = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };


    useEffect(() => {
        if (!historyData || !Array.isArray(historyData)) {
            // If historyData is undefined or not an array, set filteredHistory to an empty array
            setFilteredHistory([]);
            return;
        }
    
        const lowercasedQuery = searchQuery.toLowerCase();
        let filtered = !searchQuery ? historyData : historyData.filter(entry =>
            entry.date.toLowerCase().includes(lowercasedQuery) ||
            entry.proceedings.toLowerCase().includes(lowercasedQuery)
        );
    
        // ... rest of your sorting logic
    
        setFilteredHistory(filtered);
    }, [searchQuery, historyData]);
    




    useEffect(() => {
        dispatch(fetchHistory(id));
    }, [dispatch, id]); // Include `id` in dependencies if it's expected to change

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!proceedings || !date) {
            setErrorMessagenew('Both date and proceedings are required to update.'); // Set error message
            return; // Prevent further execution
        }

        // Existing form submission logic
        const formData = new FormData();
        formData.append('date', date);
        formData.append('proceedings', proceedings);
        formData.append('file', file);

        dispatch(updateHistory(id, formData));
        setErrorMessagenew(''); // Clear error message on successful submission
        setProceedings('')
        setDate('')
    };

    const handleDelete = (historyId) => {
        dispatch(deleteHistory(id, historyId, token));
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible); // toggle the state
    };
    if (isLoading) {
        return <div>Loading...</div>; // Show loading indicator when isLoading is true
    }





    return (
        <div className="overflow-x-auto relative">
            <h1 className="text-2xl font-bold text-gray-900 my-4 text-center bg-gray-200 p-3">
                History
            </h1>
            <div>



                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Description Date ..." required
                        onChange={(e) => {
                            setCurrentPage(1); // Reset to first page on search
                            setSearchQuery(e.target.value);
                        }} value={searchQuery} />
                    <button type="submit" class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={() => setSearchQuery("")}>Clear</button>
                </div>
            </div>
            <div className="text-center text-lg font-semibold bg-gray-100 mt-4 p-2">{title}</div>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            {historyData && (
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-6">
                                DATE
                            </th>
                            <th scope="col" className="py-3 px-6">
                                DESCRIPTION
                            </th>
                            <th scope="col" className="py-3 px-6">
                                DELETE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr
                                key={index}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            >
                                <td className="py-4 px-6">{item.date}</td>
                                <td className="py-4 px-6">{item.proceedings}</td>
                                <td className="py-4 px-6">
                                    <button className="text-red-500" onClick={() => handleDelete(item._id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}


            <div className="pagination flex justify-center items-center space-x-2 my-4">
                <button
                    disabled={currentPage === 1}
                    onClick={() => changePage(1)}
                    className={`px-4 py-2 text-sm ${currentPage === 1 ? 'cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'}`}
                >
                    First
                </button>
                <button
                    disabled={currentPage === 1}
                    onClick={() => changePage(currentPage - 1)}
                    className={`px-4 py-2 text-sm ${currentPage === 1 ? 'cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'}`}
                >
                    Prev
                </button>
                {[...Array(totalPages).keys()].map(number => (
                    <button
                        key={number}
                        onClick={() => changePage(number + 1)}
                        className={`px-4 py-2 text-sm border ${currentPage === number + 1 ? 'bg-blue-500 text-white' : 'hover:bg-blue-500 hover:text-white'}`}
                    >
                        {number + 1}
                    </button>
                ))}
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => changePage(currentPage + 1)}
                    className={`px-4 py-2 text-sm ${currentPage === totalPages ? 'cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'}`}
                >
                    Next
                </button>
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => changePage(totalPages)}
                    className={`px-4 py-2 text-sm ${currentPage === totalPages ? 'cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'}`}
                >
                    Last
                </button>
            </div>


            <div className="flex justify-center flex-col p-4">
                <button className="bg-gray-200 hover:bg-gray-300 px-4 py-2 w-52 mx-auto" onClick={toggleVisibility}>
                    {isVisible ? 'Hide' : 'Show'} Fields
                </button>

                {isVisible && (
                    <div className="flex flex-col gap-2 p-3">
                        <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Proceedings</label>
                        <textarea
                            onChange={(e) => setProceedings(e.target.value)}
                            value={proceedings}
                            type='text'
                            id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Proceedings"></textarea>


                        <div class="mb-6">
                            <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date</label>
                            <input type="text" id="default-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="00/00/0000"
                                onChange={(e) => setDate(e.target.value)}
                                value={date}
                            />
                        </div>

                        <div className="flex justify-center flex-col mx-auto">
                            <button className="bg-gray-200 px-4 py-2 w-52 mx-auto" onClick={handleSubmit}>Update</button>

                        </div>

                        <div className="text-center">
                            {errorMessagenew && <p className="text-red-500">{errorMessagenew}</p>} {/* Display error message */}
                        </div>
                    </div>
                )}
            </div>


        </div>
    );
};

export default History;
