export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';


export const ADD_CASE_START = 'ADD_CASE_START';
export const ADD_CASE_SUCCESS = 'ADD_CASE_SUCCESS';
export const ADD_CASE_FAILURE = 'ADD_CASE_FAILURE';

export const FETCH_CASES_START = 'FETCH_CASES_START';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_FAILURE = 'FETCH_CASES_FAILURE';


export const FETCH_HISTORY_START = 'FETCH_HISTORY_START'; // Add this line
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAILURE = 'FETCH_HISTORY_FAILURE';



export const DELETE_ENTRY = 'DELETE_ENTRY';


export const UPDATE_CASE_START = 'UPDATE_CASE_START';
export const UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS';
export const UPDATE_CASE_FAILURE = 'UPDATE_CASE_FAILURE';


// actionTypes.js
export const UPDATE_HISTORY_REQUEST = 'UPDATE_HISTORY_REQUEST';
export const UPDATE_HISTORY_SUCCESS = 'UPDATE_HISTORY_SUCCESS';
export const UPDATE_HISTORY_FAILURE = 'UPDATE_HISTORY_FAILURE';

export const SET_ROLES = 'SET_ROLES';
export const SET_ROLE_FAILED = 'SET_ROLE_FAILED';


// Admin
// actionTypes.js

export const FETCH_PENDING_REQUESTS_SUCCESS = 'FETCH_PENDING_REQUESTS_SUCCESS';
export const FETCH_PENDING_REQUESTS_FAIL = 'FETCH_PENDING_REQUESTS_FAIL';


// actionTypes.js
export const SET_TODAY_CASES = 'SET_TODAY_CASES';
export const All_USERS = 'All_USERS';












