export const courts = [
    { name: "Supreme Court of Pakistan" },
    { name: "High Court, Lahore" },
    { name: "High Court, Multan" },
    { name: "High Court, Rawalpindi" },
    { name: "High Court, Islamabad" },
    { name: "District Court" },
    { name: "Special Judicial Magistrate" },
    { name: "Special Judicial Magistrate-30" },
    { name: "Senior Civil Court" },
    { name: "Civil Court" },
    { name: "Family Court" },
    { name: "Guardian Court" },
    { name: "Banking Court-I" },
    { name: "Banking Court-II" },
    { name: "Banking Court-III" },
    { name: "Banking Court-IV" },
    { name: "Banking Court-V" },
    { name: "CNSA Court" },
    { name: "Anti Corruption Court" },
    { name: "ATA-I Court" },
    { name: "ATA-II Court" },
    { name: "ATA-III Court" },
    { name: "Special Tribunal" },
    { name: "Rent Tribunal" },
    { name: "PST Lahore" },
    { name: "FST Lahore" },
    { name: "Revenue Court" },
    { name: "Commissioner" },
    { name: "Special Rent Tribunal" },
    { name: "Environment Tribunal" },
    { name: "Others" }
];



export const URL_API = "https://api.zianshahlegalconsultant.com"
// export const URL_API = "http://localhost:8000"
