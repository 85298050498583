import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Radio } from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';
import { URL_API } from '../../../Components/Data';
import { TailSpin } from "react-loader-spinner";
const Edituser = () => {
    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [clientname, setClientname] = useState('')
    const [profilePicture, setProfilepicture] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        const fetchusers = async () => {
            try {
                let response = await fetch(`${URL_API}/api/v1/users/getallusers/${id}`)
                const data = await response.json()
                if (response.ok) {
                    setName(data.name)
                    setEmail(data.email)
                    setClientname(data.role)
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchusers()
    }, [])


    const updateData = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        let formData = new FormData();
        if (profilePicture) {
            formData.append("profilePicture", profilePicture);
        }

        formData.append("name", name);
        formData.append("email", email);

        if (password !== '') {
            formData.append("password", password);
        }
        formData.append("role", clientname);

        try {
            let response = await fetch(`${URL_API}/api/v1/users/editusers/${id}`, {
                method: "PUT",
                body: formData
                // Note: Removed the Content-Type header
            });

            const data = await response.json();
            if (response.ok) {
                // Replace alert with a more user-friendly notification method if possible
                toast.success(data.Message);
                setIsLoading(false)
                setTimeout(() => {
                    navigate('/users');
                }, 1000);

            } else {
                // Handle errors
                console.error('Error:', data);
                // Display error message to user
            }
        } catch (error) {
            // Handle network errors
            console.error('Network error:', error);
            // Display network error message to user
        }
    };


    return (
        <>
            <section className="flex justify-center items-center p-6">
                <div className="bg-gray-200 shadow-xl p-6 rounded-lg">
                    <h2 className="font-semibold text-2xl text-center mb-6">Edit Information</h2>
                    <div className="space-y-4">
                        <input type="text" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-200" />
                        <input type="text" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-200" />
                        <input type="password" placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-200" />
                        <input type="text" placeholder='Client Name' value={clientname} onChange={(e) => setClientname(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-200" disabled />
                        <input type="file" onChange={(e) => setProfilepicture(e.target.files[0])}
                            className="w-full px-4 py-2 border border-gray-300 rounded" />
                        <button className='w-full bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded  font-medium transition ease-in duration-200'
                            onClick={updateData} disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <div className='mx-auto flex justify-center'>
                                        <TailSpin color="#FFFFFF" height={20} width={20} />
                                    </div>
                                </>
                            ) : (
                                'Submit'
                            )}
                        </button>

                    </div>
                </div>
            </section>

            <Toaster />
        </>
    )
}

export default Edituser