import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { URL_API } from '../../Components/Data';
import axios from 'axios';


const Addroles = () => {
    const [role, setRole] = useState('');
    const [error, setError] = useState(''); // New state for error message

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!role.trim()) {
            setError('Please fill the field');
            return;
        }
    
        try {
            const response = await axios.post(`${URL_API}/api/v1/users/addrole`, { role });
            if (response.status === 200) {
                alert(response.data.Message); // Displaying the success message from the server
            } else {
                alert('Failed to add role'); // Handling any other status codes as failure
            }
        } catch (error) {
            if (error.response) {
                alert(error.response.data.Message); // Displaying server provided error message
            } else {
                alert('Failed to add role'); // Displaying generic error message for other errors
            }
        }
    };
    
    return (
        <>
            <section className="p-4 text-center">
                <div className="bg-gray-200 shadow-xl p-4 font-semibold text-2xl admin-dashboard-title">
                    Settings
                </div>
            </section>

            <div className='flex justify-evenly space-x-2 mt-3'>
                <TextField
                    className='w-full mb-4'
                    label="Client Name"
                    variant="outlined"
                    onChange={(e) => setRole(e.target.value)}
                    value={role}
                    error={!!error} // Display error styling if error is present
                    helperText={error} // Display error message
                />

                <div className='flex justify-center'>
                    <button onClick={handleFormSubmit} className='bg-green-500 px-7 py-2 rounded'>Add</button>
                </div>
            </div>
        </>
    );
}

export default Addroles;
