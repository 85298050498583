import {
    LOGIN_SUCCESS, LOGIN_FAILURE, FETCH_CASES_START, FETCH_CASES_SUCCESS, FETCH_CASES_FAILURE,
    FETCH_HISTORY_SUCCESS, FETCH_HISTORY_FAILURE, FETCH_HISTORY_START, DELETE_ENTRY, UPDATE_CASE_START, UPDATE_CASE_SUCCESS,
    UPDATE_CASE_FAILURE, ADD_CASE_START, ADD_CASE_SUCCESS, ADD_CASE_FAILURE, UPDATE_HISTORY_REQUEST, UPDATE_HISTORY_SUCCESS, UPDATE_HISTORY_FAILURE, SET_ROLES, SET_ROLE_FAILED,
    FETCH_PENDING_REQUESTS_SUCCESS, SET_TODAY_CASES, All_USERS


} from '../Actions/Actiontypes';

// Expanded initialState to include user and isAuthenticated
const initialState = {
    cases: [],
    user: null,
    isAuthenticated: false,
    isLoading: false,
    error: null,
    data: null,
    roles: [],
    pendingrequest: [],
    todayCases: [],   // Add todayCases to your state
    allusers: []

};

const caseReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_CASE_START:
            return {
                ...state,
                isLoading: true,
                error: null, // Optionally reset the error at the start of the operation
            };
        case ADD_CASE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cases: [...state.cases, action.payload], // Add the new case to the list of cases
                error: null, // Clear any errors
            };
        case ADD_CASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload, // Set the received error
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload, // Set user data on login success
                isAuthenticated: true, // Update authentication status
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                user: null, // Clear user data on login failure
                isAuthenticated: false, // Update authentication status
            };
        case FETCH_CASES_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_CASES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cases: action.payload.cases, // Assuming the payload contains the cases and other necessary data
                totalPages: action.payload.totalPages, // Assuming you want to store total pages
            };
        case FETCH_CASES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case FETCH_HISTORY_START:
            return {
                ...state,
                isLoading: true, // Start loading
                error: null, // Optionally reset errors
            };
        case FETCH_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                error: null
            };
        case FETCH_HISTORY_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
                data: null
            };
        case DELETE_ENTRY:
            const updatedEntries = state.cases.filter(entry => entry._id !== action.payload);
            return { ...state, cases: updatedEntries };
        case UPDATE_CASE_START:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_CASE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cases: state.cases.map(caseItem =>
                    caseItem._id === action.payload._id ? action.payload : caseItem
                ),
                // Optionally, update a message or success flag
            };
        case UPDATE_CASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case UPDATE_HISTORY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case UPDATE_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case UPDATE_HISTORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SET_ROLES:
            return { ...state, roles: action.payload };
        case SET_ROLE_FAILED:
            return { ...state, error: action.payload }

        case FETCH_PENDING_REQUESTS_SUCCESS:
            return { ...state, pendingrequest: action.payload }


        case SET_TODAY_CASES:
            return { ...state, todayCases: action.payload }; // Handle SET_TODAY_CASES
        case All_USERS:
            return { ...state, allusers: action.payload }; // Handle SET_TODAY_CASE

        default:
            return state;
    }
};

export default caseReducer;
