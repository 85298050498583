import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import Doughunt from './Doughunt';

const BarChart = () => {
  const data = {
    labels: ['Total Cases', 'Today\'s Cases', 'Pending Approvals', 'Total Users'],
    datasets: [
      {
        label: 'Count',
        data: [94, 30, 20, 50],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>

      <Bar data={data} />


    </>
  );
};

export default BarChart;
