import React from 'react';
import { motion } from 'framer-motion';

const AboutUs = () => {
  const containerVariants = {
    hidden: { 
      opacity: 0, 
      x: '-100vw' 
    },
    visible: { 
      opacity: 1, 
      x: 0,
      transition: { 
        type: 'spring',
        mass: 0.4,
        damping: 8,
        when: "beforeChildren",
        staggerChildren: 0.4
      }
    },
  };

  return (
    <>
    <motion.div
      className="flex  justify-center h-screen bg-gray-100 px-6"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className='flex flex-col items-center text-justify space-y-6 p-4'>

        <p className='text-2xl font-bold tracking-wider'>PLACEMENT ON PANEL OF LEGAL ADVISORS</p>

        <p>Our firm, Zia & Shah, was founded in May 2010 on the dissolution of our predecessor law firm, “Afridi, Shah & Minallah (ASM) Legal Consultants” due to the elevation of its members to the benches of High Courts and now the Supreme Court of Pakistan. The said firm had been dealing with Corporate and Civil Litigation as its foundational core.  ASM consisted of 30 lawyers (Partners and Associates) working at Lahore, Islamabad and Peshawar offices.  The said firm extended its work for providing consultancy services relating to legal reforms and development.   </p>

        <p>Zia & Shah consists of seven lawyers working at Lahore at three different locations and has taken over the professional assignments / cases of the defunct firm “Afridi, Shah & Minallah”. Our aim, like our predecessor, is to render legal services with integrity and scholarship which the profession of law requires. Corporate and Civil litigation is the foundational core of the Firm. However, it also has key expertise in commercial and white collar crime cases. It also benefits from experience of two of its current partners in policy work in criminal law/prosecution and police reform.  The Firm continues to play a lead role in public interest litigation pertaining to human rights and environmental issues.  One of the core values is its dedication to legal education, reform and training. We feel that the real purpose of the law Firm is to make a wholesome contribution to society. Our multidimensional efforts are our small contribution to the socio-economic development of our Country.</p>

        <p className='text-xl font-semibold tracking-wider'>Mission Statement</p>
        <p>Zia & Shah envisions to ensure access to justice by providing highly professional and scholastic legal services with dedication, responsibility and integrity. The Firm also aims at providing pro bono legal services to the society in general so that the rights enjoyed by citizens in common are not impaired. The Firm intensely aims at contributing towards the development of law and the process of administration of justice by resorting to meaningful measures for the promotion of legal education and for raising awareness among the masses as to their rights and obligations.  </p>
      

      </div>


     

     
      
    </motion.div>
    
    </>
    
  );
};

export default AboutUs;
