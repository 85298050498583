import { URL_API } from "../Components/Data";
import {
    LOGIN_SUCCESS, FETCH_CASES_START, FETCH_CASES_SUCCESS, FETCH_CASES_FAILURE,
    FETCH_HISTORY_SUCCESS, FETCH_HISTORY_FAILURE, FETCH_HISTORY_START, DELETE_ENTRY, UPDATE_CASE_START, UPDATE_CASE_SUCCESS,
    UPDATE_CASE_FAILURE, ADD_CASE_START, ADD_CASE_SUCCESS, ADD_CASE_FAILURE, UPDATE_HISTORY_REQUEST, UPDATE_HISTORY_SUCCESS, UPDATE_HISTORY_FAILURE, SET_ROLES, SET_ROLE_FAILED,
    FETCH_PENDING_REQUESTS_SUCCESS, FETCH_PENDING_REQUESTS_FAIL, SET_LOADING, SET_TODAY_CASES, All_USERS
} from "./Actiontypes";
import axios from "axios";
import { toast } from "react-hot-toast";

// const user = sessionStorage.getItem('user')
// const token = user ? JSON.parse(user).token : null

// Function to get the current user's token
export const getToken = () => {
    const user = sessionStorage.getItem('user');
    return user ? JSON.parse(user).token : null;
};




export const addCaseStart = () => {
    return {
        type: ADD_CASE_START,
    };
};

// Action to dispatch when add case process is successful
// Assuming caseData is the data of the newly added case
export const addCaseSuccess = (caseData) => {
    return {
        type: ADD_CASE_SUCCESS,
        payload: caseData,
    };
};

// Action to dispatch when add case process fails
// Assuming error is the error information received
export const addCaseFailure = (error) => {
    return {
        type: ADD_CASE_FAILURE,
        payload: error,
    };
};

export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
});



export const fetchCasesStart = () => ({
    type: FETCH_CASES_START,
});

export const fetchCasesSuccess = (data) => ({
    type: FETCH_CASES_SUCCESS,
    payload: data,
});

export const fetchCasesFailure = (error) => ({
    type: FETCH_CASES_FAILURE,
    payload: error,
});

export const deleteEntrySuccess = (id) => ({
    type: DELETE_ENTRY,
    payload: id,
});

export const updateHistoryRequest = () => ({
    type: UPDATE_HISTORY_REQUEST,
});

export const updateHistorySuccess = (newHistoryItem) => ({
    type: UPDATE_HISTORY_SUCCESS,
    payload: newHistoryItem,
});

export const updateHistoryFailure = (error) => ({
    type: UPDATE_HISTORY_FAILURE,
    payload: error,
});

export const setRoles = (data) => ({
    type: SET_ROLES,
    payload: data,
});

export const setrolefailed = (error) => ({
    type: SET_ROLE_FAILED,
    payload: error,
});


export const getallpendingrequests = (userData) => ({
    type: FETCH_PENDING_REQUESTS_SUCCESS,
    payload: userData,
});


export const setError = (error) => ({
    type: FETCH_PENDING_REQUESTS_FAIL,
    payload: error,
});


export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading,
});


export const setTodayCases = (data) => ({
    type: SET_TODAY_CASES,
    payload: data,
});

export const setAllusers = (data) => ({
    type: All_USERS,
    payload: data,
})






// --------------------------------------------------------------------------------------------------------------------

export const Addcases = (casedata, token) => async (dispatch) => {
    dispatch(addCaseStart()); // Dispatch the start action
    try {
        const response = await axios.post(
            `${URL_API}/api/v1/cases/add-case`,
            casedata,
            {
                headers: {
                    'Authorization': `Bearer ${token}` // Include the token in the header
                }
            }
        );
        dispatch(addCaseSuccess(response.data)); // Dispatch the success action with the response data
        toast.success(response.data.Message); // Show success message
    } catch (error) {
        let errorMessage = "An error occurred";
        if (error.response) {
            console.error("Error adding case", error.response.data);
            errorMessage = error.response.data.Message; // Assuming the error message is in the response's Message property
        } else if (error.request) {
            console.error("Error adding case", error.request);
        } else {
            console.error("Error", error.message);
        }
        dispatch(addCaseFailure(errorMessage)); // Dispatch the failure action with the error message
        toast.error(errorMessage); // Show error message
    }
};

export const fetchAllCases = (debouncedSearchTerm, currentPage) => async (dispatch) => {
    dispatch(fetchCasesStart());
    const baseUrl = `${URL_API}/api/v1/cases/all-cases`;
    const searchQuery = debouncedSearchTerm ? `search=${debouncedSearchTerm}` : '';
    const pageQuery = `page=${currentPage}`;
    const query = `?${[searchQuery, pageQuery].filter(Boolean).join('&')}`;

    try {
        const response = await axios.get(`${baseUrl}${query}`);
        dispatch(fetchCasesSuccess(response.data));
    } catch (error) {
        dispatch(fetchCasesFailure(error.toString()));
    }
}


export const fetchHistory = (caseId) => async (dispatch) => {
    dispatch({ type: FETCH_HISTORY_START }); // Correctly dispatch the start of the loading process
    try {
        const url = `${URL_API}/api/v1/cases/gethistory/${caseId}`;
        const response = await axios.get(url);
        console.log('API Response:', response.data);
        dispatch({ type: FETCH_HISTORY_SUCCESS, payload: response.data }); // Only dispatch history array

    } catch (error) {
        console.error(error);
        dispatch({ type: FETCH_HISTORY_FAILURE, error: 'Failed to fetch case history' }); // Dispatch failure with error message
    }
};


export const deleteEntry = (id) => async (dispatch) => {
    const token = getToken();
    try {
        const response = await axios.delete(`${URL_API}/api/v1/cases/deleteentries/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the header
            }
        });
        if (response.status === 200) {
            dispatch(deleteEntrySuccess(id));
            toast.success(response.data.Message); // Or handle the user feedback more sophisticatedly
        }
    } catch (error) {
        console.error('Error deleting the case: ', error);
        if (error.response && error.response.status === 403) {
            toast.error(error.response.data.Message); // Display the error message returned from the server
        } else {
            toast.error('Failed to delete the case'); // Or display a generic error message
        }
    }
};



// // In your actions file
// export const rehydrateAuth = () => (dispatch) => {
//     const userData = localStorage.getItem('user');
//     if (userData) {
//         dispatch(loginSuccess(JSON.parse(userData)));
//     } else {
//         dispatch(logoutSuccess()); // Or simply do nothing if you prefer not to dispatch on null userData
//     }
// };




export const updateCase = (caseId, formData) => async (dispatch) => {
    const token = getToken();
    dispatch({ type: UPDATE_CASE_START });
    try {
        const response = await axios.put(`${URL_API}/api/v1/cases/editentries/${caseId}`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        dispatch({ type: UPDATE_CASE_SUCCESS, payload: response.data });
        toast.success(response.data.message || "Entry updated successfully");
    } catch (error) {
        console.error('Error updating case:', error);
        dispatch({ type: UPDATE_CASE_FAILURE, payload: error.response?.data?.message || "Failed to update entry" });
        toast.error(error.response?.data?.message || "Failed to update entry");
    }
};

export const updateHistory = (caseId, formData) => async (dispatch) => {
    dispatch(updateHistoryRequest());
    const url = `${URL_API}/api/v1/cases/updateschema/${caseId}`;

    try {
        // Make the API request
        const response = await axios.put(url, formData);

        // Dispatch success action with the response data
        dispatch(updateHistorySuccess(response.data));


        // Dispatch fetchHistory to refresh the history data
        dispatch(fetchHistory(caseId));
        toast.success("Added Successfully");
    } catch (error) {
        // Dispatch failure action if there's an error
        dispatch(updateHistoryFailure(error));
    }
};

export const deleteHistory = (caseId, historyId, token) => async dispatch => {
    const token = getToken();
    try {
        const response = await axios.delete(`${URL_API}/api/v1/cases/caseentries/${caseId}/history/${historyId}`, {
            headers: {
                'Authorization': `Bearer ${token}` // Include the token in the header
            }
        });


        toast.success(response.data.Message);
        dispatch(fetchHistory(caseId))

    } catch (error) {


        // Show error alert
        toast.error(error.response ? error.response.data.Message : "An error occurred");
    }
};


export const fetchRoles = () => async (dispatch) => {
    try {
        const response = await axios.get(`${URL_API}/api/v1/users/getrole`);
        if (response.data.length > 0) {
            dispatch(setRoles(response.data[0].role)); // Assuming the roles are in the first object of the array
        }
    } catch (error) {
        dispatch(setrolefailed(error));
    }
};


// Admin
export const pendingrequests = () => async (dispatch) => {
    try {

        const response = await fetch(`${URL_API}/api/v1/admin/pending-request`)
        const data = await response.json();
        dispatch(getallpendingrequests(data));
    } catch (error) {
        dispatch(setError(error));
        toast.error('An error occurred during the Fetching');
    }
}


// export const fetchTodayHearings = () => {
//     return dispatch => {
//         dispatch(fetchHearingsRequest());
//         axios.get(`${URL_API}/api/v1/cases/today-cases`)
//             .then(response => {
//                 const hearings = response.data;
//                 dispatch(fetchHearingsSuccess(hearings));
//             })
//             .catch(error => {
//                 dispatch(fetchHearingsFailure(error.message));
//             });
//     };
// };


export const fetchTodayHearings = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const response = await axios.get(`${URL_API}/api/v1/cases/today-cases`);
        dispatch(setTodayCases(response.data));
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setError(error));
        dispatch(setLoading(false));
    }
};



export const Getallusers = () => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        let response = await axios.get(`${URL_API}/api/v1/users/getallusers`);
        dispatch(setAllusers(response.data))

        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setError(error));
        dispatch(setLoading(false));
    }
}










