import React from 'react'
import DashboardCards from '../../../Components/Portal/Dashboardcards'
import BarChart from '../../../Components/Portal/Charts/Barchat'
import LineChart from '../../../Components/Portal/Charts/Linechart'



const Dashboard = () => {
  return (
    <>
      <DashboardCards />

      <div className='grid grid-cols-1 md:grid-cols-2 gap-10 mt-10'>
        <div> <BarChart /></div>
        <div> <LineChart /></div>
      </div>




    </>


  )
}

export default Dashboard
