import React from "react";

const DeleteModal = ({ isOpen, onClose, onDelete }) => {


    return (
        <>
            {isOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto ml-52">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-25"></div>
                        </div>
                        <div className="relative bg-white p-8 rounded-lg max-w-md">
                            <div className="mb-4">
                                <h2 className="text-lg font-semibold mb-2">Are you sure you want to delete this case?</h2>
                                <p className="text-gray-600">This action cannot be undone.</p>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded mr-2"
                                    onClick={() => {
                                        onDelete();
                                        onClose();
                                    }}
                                >
                                    Delete
                                </button>
                                <button
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold px-4 py-2 rounded"
                                    onClick={onClose}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DeleteModal;
