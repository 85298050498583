import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { URL_API } from '../../../Components/Data'
import { useDispatch, useSelector } from 'react-redux';
import { pendingrequests } from '../../../Actions/Actions';
import { Link } from 'react-router-dom';

const Pendingrequest = () => {
    const dispatch = useDispatch();
    const pendingrequest = useSelector((state) => state.case.pendingrequest);



    useEffect(() => {
        dispatch(pendingrequests());
    }, [dispatch]);

    const Approvehandler = async (selectedValue, id) => {
        if (window.confirm(`Are you sure to ${selectedValue} this Request`)) {
            try {
                let response = await axios.post(`${URL_API}/api/v1/admin/approve-req/${id}`, { status: selectedValue });

                if (response.status === 200) {
                    // Handle success - maybe update the UI or show a success message
                    console.log("Request updated successfully:", response.data);
                    // Additional UI feedback can be added here
                } else {
                    // Handle non-success status codes
                    console.log("Failed to update request:", response.status, response.data);
                    // Additional UI feedback for failure can be added here
                }
            } catch (error) {
                // Handle errors in the request itself (e.g., network errors)
                console.error("Error during update request:", error);
                // Additional UI error handling can be added here
            }
        }
    };


    return (
        <div className='w-full p-4'>
            <section className="text-center mb-8">
                <h1 className="text-3xl font-semibold text-gray-800 bg-blue-100 rounded-md p-4 shadow-md">
                    Pending Approvals
                </h1>
            </section>
            <div className="overflow-x-auto rounded-lg shadow-md">
                <table className="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase bg-gray-50">
                                #
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase bg-gray-50">
                                Suit No
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase bg-gray-50">
                                Title
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase bg-gray-50">
                                User Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase bg-gray-50">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase bg-gray-50">
                                Approve
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-semibold tracking-wider text-left text-gray-700 uppercase bg-gray-50">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingrequest.map((item, index) => (
                            <tr key={item._id} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-50">
                                <td className="px-6 py-4 text-sm text-gray-700">{index + 1}</td>
                                <td className="px-6 py-4 text-sm text-gray-700">{item.caseId.Suitno}</td>
                                <td className="px-6 py-4 text-sm text-gray-700">{item.caseId.title}</td>
                                <td className="px-6 py-4 text-sm text-gray-700">{item.userId.name}</td>
                                <td className="px-6 py-4 text-sm text-gray-700">{item.status}</td>
                                <td className="px-6 py-4 text-sm">
                                    <select className='w-full p-2 text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:border-gray-400 focus:outline-none focus:border-blue-300'
                                        onChange={(e) => Approvehandler(e.target.value, item.caseId._id)}>
                                        <option value="">--Select--</option>
                                        <option value="approved">Approve</option>
                                        <option value="rejected">Reject</option>
                                    </select>
                                </td>
                                <td className="px-6 py-4 text-sm">
                                    <Link to={`/edit-case/${item.caseId._id}`} className="text-blue-600 hover:text-blue-900">
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    )
}

export default Pendingrequest
