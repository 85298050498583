import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { URL_API, courts } from "../../../Components/Data";
import { useParams } from "react-router-dom";
import { updateCase } from "../../../Actions/Actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";



const Editcase = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const user = sessionStorage.getItem('user')
    const token = user ? JSON.parse(user).token : null

    const { isLoading } = useSelector((state) => ({

        isLoading: state.case.isLoading,

    }));


    // Toggle usestates
    const [addcase, setAddcase] = useState(false);
    const [plaintiffs, setPlaintiffs] = useState(false)
    const [defendant, setDefendant] = useState(false)
    const [witness, setWitness] = useState(false)
    const [application, setApplication] = useState(false)
    const [dates, setDates] = useState(false)

    //  Input Fields Usestates
    const [suitno, setSuitno] = useState('');
    const [title, setTitle] = useState('');
    const [court, setCourt] = useState('');
    const [lawyer, setLawyer] = useState('');
    const [client, setClient] = useState('');
    const [valueofsuit, setValueofsuit] = useState("");
    const [natureOfSuit, setNatureOfSuit] = useState("");
    const [suitproperty, setSuitproperty] = useState("");
    const [plaintiffsClaim, setPlaintiffsClaim] = useState("");
    const [defendantsArgument, setDefendantsArgument] = useState("");
    const [currentStatus, setCurrentStatus] = useState("");
    const [plaintiffsRep, setPlaintiffsRep] = useState("");
    const [defendantRep, setDefendantRep] = useState("");
    const [restrainingOrder, setRestrainingOrder] = useState("");
    const [UnnecessaryAdjournments, setUnnecessaryadjournments] = useState('')
    const [other, setOther] = useState('')
    const [wordfile, setWordfile] = useState("");

    // Plaintiff's Submitted Documents
    const [plaintiffsSubmittedDocument, setPlaintiffsSubmittedDocument] = useState("");
    const [plaintiffsSubmittedDocuments, setPlaintiffsSubmittedDocuments] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [editValue, setEditValue] = useState("");
    const handleAddPlaintiffsSubmittedDocument = () => {
        if (plaintiffsSubmittedDocument) {
            setPlaintiffsSubmittedDocuments((prevDocs) => [
                ...prevDocs,
                plaintiffsSubmittedDocument,
            ]);
            setPlaintiffsSubmittedDocument(""); // Reset the input field after adding
        }
    };
    const startEdit = (index, value) => {
        setEditIndex(index);
        setEditValue(value);
    };

    const saveEdit = (index) => {
        const updatedDocs = [...plaintiffsSubmittedDocuments];
        updatedDocs[index] = editValue;
        setPlaintiffsSubmittedDocuments(updatedDocs);
        setEditIndex(-1);
        setEditValue("");
    };


    // Additional Plaintiff's Documents
    const [additionalPlaintiffDocuments, setAdditionalPlaintiffDocuments] = useState([]);
    const [additionalPlaintiffDocument, setAdditionalPlaintiffDocument] = useState("");
    const [editIndexa, setEditIndexa] = useState(-1);
    const [editValuea, setEditValuea] = useState("");
    // working
    const handleAdditionalPlaintiffDocuments = () => {
        if (additionalPlaintiffDocument) {
            setAdditionalPlaintiffDocuments((prevDocs) => [
                ...prevDocs,
                additionalPlaintiffDocument,
            ]);
            setAdditionalPlaintiffDocument(""); // Reset the input field after adding
        }
    };
    // Additional plantiffs
    const startAEdit = (index, value) => {
        setEditIndexa(index);
        setEditValuea(value);
    };

    const saveAEdit = (index) => {
        const updatedDocs = [...additionalPlaintiffDocuments];
        updatedDocs[index] = editValuea;
        setAdditionalPlaintiffDocuments(updatedDocs);
        setEditIndexa(-1);
        setEditValuea("");
    };


    // Defendent's submitted documents
    const [defendantSubmittedDocumentmessage, setDefendantSubmittedDocumentmessage] = useState('');
    const [defendantsSubmittedDocuments, setDefendantsSubmittedDocuments] = useState([]);
    const [editIndex1, setEditIndex1] = useState(-1);
    const [editValue1, setEditValue1] = useState("");


    const handleAdddefendantsSubmittedDocuments = () => {
        if (defendantSubmittedDocumentmessage) {
            setDefendantsSubmittedDocuments((prevDocs) => [
                ...prevDocs,
                defendantSubmittedDocumentmessage,
            ]);
            setDefendantSubmittedDocumentmessage(""); // Reset the input field after adding
        }
    };
    // defendants
    const startdEdit = (index, value) => {
        setEditIndex1(index);
        setEditValue1(value);
    };
    const savedEdit = (index) => {
        const updatedDocs = [...defendantsSubmittedDocuments];
        updatedDocs[index] = editValue1;
        setDefendantsSubmittedDocuments(updatedDocs);
        setEditIndex1(-1);
        setEditValue1("");
    };


    // Additional Defendant's Documents
    const [additionalDefendantDocuments, setAdditionalDefendantDocuments] = useState([]);
    const [additionalDefendantDocument, setAdditionalDefendantDocument] = useState("");
    const [editIndexd, setEditIndexd] = useState(-1);
    const [editValued, setEditValued] = useState("");
    // defendants additional
    const handleAdditionalDefendantDocument = () => {
        if (additionalDefendantDocument) {
            setAdditionalDefendantDocuments((prevDocs) => [
                ...prevDocs,
                additionalDefendantDocument,
            ]);
            setAdditionalDefendantDocument(""); // Reset the input field after adding
        }
    };
    // defendants additional
    const startDEdit = (index, value) => {
        setEditIndexd(index);
        setEditValued(value);
    };
    const savedDEdit = (index) => {
        const updatedDocs = [...additionalDefendantDocuments];
        updatedDocs[index] = editValued;
        setAdditionalDefendantDocuments(updatedDocs);
        setEditIndexd(-1);
        setEditValued("");
    };


    const [formData, setFormData] = useState({
        srNo: "",
        application: "",
        applicationDate: "",
        reply: "",
        replyDate: "",
    });
    const [editIndexap, setEditIndexap] = useState(-1);
    const [entries, setEntries] = useState([]);


    // Application
    const handleAddClick = () => {
        if (editIndexap === -1) {
            // Create a new entry object from the formData
            const newEntry = {
                srNo: formData.srNo,
                application: formData.application,
                applicationDate: formData.applicationDate,
                reply: formData.reply,
                replyDate: formData.replyDate,
            };

            // Add the new entry to the entries array
            setEntries([...entries, newEntry]);

            // Clear the formData for the next entry
            setFormData({
                srNo: "",
                application: "",
                applicationDate: "",
                reply: "",
                replyDate: "",
            });
        } else {
            // Edit existing entry
            const updatedEntries = [...entries];
            updatedEntries[editIndexap] = {
                srNo: formData.srNo,
                application: formData.application,
                applicationDate: formData.applicationDate,
                reply: formData.reply,
                replyDate: formData.replyDate,
            };

            setEntries(updatedEntries);
            setEditIndexap(-1);

            // Clear the formData for the next entry
            setFormData({
                srNo: "",
                application: "",
                applicationDate: "",
                reply: "Not Available",
                replyDate: "",
            });
        }
    };




    const handleDeleteClick = (index) => {
        // Create a new array by removing the entry at the specified index
        const updatedEntries = [...entries];
        updatedEntries.splice(index, 1);

        // Update the state with the new array of entries
        setEntries(updatedEntries);
    };

    // Usage of the


    const handleEditClick = (index) => {
        const entryToEdit = entries[index];
        setFormData(entryToEdit);
        setEditIndexap(index);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const [noOfWitnessesOfPlaintiff, setNoOfWitnessesOfPlaintiff] = useState("");
    const [noOfWitnessesOfDefendant, setNoOfWitnessesOfDefendant] = useState("");
    const [WitnessesAvailablelist, setWitnessesAvailablelist] = useState('');
    const [DefendentAvailablelist, setDefendentAvailablelist] = useState('');


    const [filingOfSuit, setFilingOfSuit] = useState("");
    const [numberOfDefendants, setNumberOfDefendants] = useState("");
    const [poaFilingDatePlaintiff, setPoaFilingDatePlaintiff] = useState("");
    const [poaFilingDateDefendant, setPoaFilingDateDefendant] = useState("");
    const [defendantsWrittenStatementDate, setDefendantsWrittenStatementDate] = useState("");
    const [issuesFramedDate, setIssuesFramedDate] = useState("");
    const [restrainingOrderDate, setRestrainingOrderDate] = useState("");
    const [prevhearing, setPrevHearing] = useState("");
    const [nexthearing, setNextHearing] = useState("");





    const toggleaddcase = () => setAddcase((prev) => !prev);
    const toggleplaintiffs = () => setPlaintiffs((prev) => !prev);
    const toggleDefendant = () => setDefendant((prev) => !prev)
    const togglewitness = () => setWitness((prev) => !prev)
    const toggleapplication = () => setApplication((prev) => !prev)
    const toggledates = () => setDates((prev) => !prev)


    const Updatecasehandler = async (e) => {
        e.preventDefault()
        let formData = new FormData();

        // dispatch(updateCase(id, formData, token));
        formData.append("Suitno", suitno);
        formData.append("title", title);
        formData.append("court", court);
        formData.append("lawyer", lawyer);
        formData.append("Clientname", client);
        formData.append("Valueofsuit", valueofsuit);
        formData.append("NatureofSuit", natureOfSuit);
        formData.append("Suitproperty", suitproperty);
        formData.append("PlaintiffsClaim", plaintiffsClaim);
        formData.append("DefendantsArgument", defendantsArgument);
        formData.append("CurrentStatus", currentStatus);
        formData.append("PlaintiffsRepresentation", plaintiffsRep);
        formData.append("Defendantrepresentative", defendantRep);
        formData.append("RestrainingOrder", restrainingOrder);
        formData.append("UnnecessaryAdjournments", UnnecessaryAdjournments);
        formData.append("others", other);
        if (wordfile) {
            formData.append("wordFile", wordfile);
        }


        // 
        plaintiffsSubmittedDocuments.forEach((doc, index) => {
            formData.append(`PlaintiffsSubmittedDocuments[${index}]`, doc);
        });
        additionalPlaintiffDocuments.forEach((doc, index) => {
            formData.append(`AdditionalPlaintiffDocuments[${index}]`, doc);
        });
        defendantsSubmittedDocuments.forEach((doc, index) => {
            formData.append(`DefendantsSubmittedDocuments[${index}]`, doc);
        });

        additionalDefendantDocuments.forEach((doc, index) => {
            formData.append(`AdditionalDefendantDocuments[${index}]`, doc);
        });

        formData.append("WitnessesAvailablelist", WitnessesAvailablelist);
        formData.append("DefendentAvailablelist", DefendentAvailablelist);

        entries.forEach((entry, index) => {
            Object.keys(entry).forEach((key) => {
                formData.append(`application[${index}][${key}]`, entry[key]);
            });
        });


        formData.append("filingOfSuit", filingOfSuit);
        formData.append("numberOfDefendants", numberOfDefendants);
        formData.append("poaFilingDatePlaintiff", poaFilingDatePlaintiff);
        formData.append("poaFilingDateDefendant", poaFilingDateDefendant);
        formData.append("defendantsWrittenStatementDate", defendantsWrittenStatementDate);
        formData.append("issuesFramedDate", issuesFramedDate);
        formData.append("restrainingOrderDate", restrainingOrderDate);
        formData.append("prevhearing", prevhearing);
        formData.append("nexthearing", nexthearing);

        dispatch(updateCase(id, formData, token));



    }



    useEffect(() => {
        const fetchgetcaseonid = async () => {
            try {
                let response = await fetch(`${URL_API}/api/v1/cases/getonid/${id}`)
                const data = await response.json()
                if (data) {
                    setSuitno(data.suitno ? data.suitno : data.Suitno)
                    setTitle(data.title);
                    setCourt(data.court);
                    setLawyer(data.lawyer);
                    setClient(data.client)
                    setValueofsuit(data.Valueofsuit)
                    setNatureOfSuit(data.NatureofSuit ? data.NatureofSuit : data.nature)
                    setSuitproperty(data.Suiteproperty)
                    setPlaintiffsClaim(data.PlaintiffsClaim)
                    setDefendantsArgument(data.DefendantsArgument)
                    setCurrentStatus(data.CurrentStatus)
                    setPlaintiffsRep(data.PlaintiffsRepresentation)
                    setDefendantRep(data.Defendantrepresentative)
                    setRestrainingOrder(data.RestrainingOrder)
                    setUnnecessaryadjournments(data.UnnecessaryAdjournments)
                    setOther(data.others)

                    setPlaintiffsSubmittedDocuments(data.PlaintiffsSubmittedDocuments)
                    setAdditionalPlaintiffDocuments(data.AdditionalPlaintiffDocuments);
                    setDefendantsSubmittedDocuments(data.DefendantsSubmittedDocuments)
                    setAdditionalDefendantDocuments(data.AdditionalDefendantDocuments)


                    setNoOfWitnessesOfPlaintiff(data.NoofWitnessesofPlaintiff)
                    setNoOfWitnessesOfDefendant(data.NoofWitnessesofDefendant)
                    setWitnessesAvailablelist(data.WitnessesAvailablelist)
                    setDefendentAvailablelist(data.DefendentAvailablelist)
                    setEntries(data.application)


                    setFilingOfSuit(data.filingOfSuit)
                    setNumberOfDefendants(data.numberOfDefendants)
                    setPoaFilingDatePlaintiff(data.poaFilingDatePlaintiff)
                    setPoaFilingDateDefendant(data.poaFilingDateDefendant)
                    setDefendantsWrittenStatementDate(data.defendantsWrittenStatementDate)
                    setIssuesFramedDate(data.issuesFramedDate)
                    setRestrainingOrderDate(data.restrainingOrderDate)
                    setClient(data.Clientname)
                    setValueofsuit(data.Valueofsuit)
                    setPrevHearing(data.prevhearing)
                    setNextHearing(data.nexthearing)



                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchgetcaseonid()
    }, [])


    const requesthandler = async () => {
        try {

            const response = await fetch(`${URL_API}/api/v1/admin/reqedit/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': token
                }
            });

            const data = await response.json();

            if (response.status === 200) {
                alert('Edit request submitted successfully');
            } else {
                alert(data.message || 'Error submitting edit request');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to send edit request');
        }
    }


    return (
        <>
            <div className="bg-gray-100 h-auto p-5  w-full flex items-center justify-center">
                <div className="w-full mx-auto bg-white p-4 shadow-lg">
                    <h2 className="text-lg font-semibold text-center">Edit Case</h2>

                    <form onSubmit={Updatecasehandler}>
                        <div
                            className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer  shadow-lg  flex justify-center p-4 mt-4"
                            onClick={toggleaddcase}
                        >
                            Edit Case
                            {/* arrow icon */}
                        </div>
                        {addcase && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 1 */}

                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Suitno"
                                        onChange={(e) => setSuitno(e.target.value)}
                                        value={suitno}
                                    />

                                    {/* 2 */}

                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Title"
                                        onChange={(e) => setTitle(e.target.value)}
                                        value={title}
                                    />

                                    {/* 3 */}
                                    <select
                                        id="countries"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        value={court}
                                        onChange={(e) => setCourt(e.target.value)}
                                    >
                                        <option selected>Choose a Court</option>
                                        {courts.map((court) => (
                                            <option key={court.id} value={court.id}>
                                                {court.name}
                                            </option>
                                        ))}
                                    </select>

                                    {/* 4 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Lawyer Name"
                                        onChange={(e) => setLawyer(e.target.value)}
                                        value={lawyer}
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 5 */}

                                    <select
                                        id="countries"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        value={client}
                                        onChange={(e) => setClient(e.target.value)}
                                    >
                                        <option selected>Choose Client</option>
                                        {courts.map((court) => (
                                            <option key={court.id} value={court.id}>
                                                {court.name}
                                            </option>
                                        ))}
                                    </select>

                                    {/* 6 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Value of Suit"
                                        onChange={(e) => setValueofsuit(e.target.value)}
                                        value={valueofsuit}
                                    />

                                    {/* 7 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Nature of Suit"
                                        onChange={(e) => setNatureOfSuit(e.target.value)}
                                        value={natureOfSuit}
                                    />
                                    {/* 8 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Suit property"
                                        onChange={(e) => setSuitproperty(e.target.value)}
                                        value={suitproperty}
                                    />

                                </div>


                                {/* 3rd Row */}
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 9 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Plaintiff's Claim"
                                        onChange={(e) => setPlaintiffsClaim(e.target.value)}
                                        value={plaintiffsClaim}
                                    />



                                    {/* 10 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Defendant's Argument"
                                        onChange={(e) => setDefendantsArgument(e.target.value)}
                                        value={defendantsArgument}
                                    />

                                    {/* 11 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Current Status"
                                        onChange={(e) => setCurrentStatus(e.target.value)}
                                        value={currentStatus}
                                    />
                                    {/* 12 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Plaintiff's Representation"
                                        onChange={(e) => setPlaintiffsRep(e.target.value)}
                                        value={plaintiffsRep}
                                    />

                                </div>
                                {/* 4th Row */}
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 10 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Defendant representative"
                                        onChange={(e) => setDefendantRep(e.target.value)}
                                        value={defendantRep}
                                    />



                                    {/* 11 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Restraining Order"
                                        onChange={(e) => setRestrainingOrder(e.target.value)}
                                        value={restrainingOrder}
                                    />

                                    {/* 12 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Unnecessary Adjournments"
                                        onChange={(e) => setUnnecessaryadjournments(e.target.value)}
                                        value={UnnecessaryAdjournments}
                                    />
                                    {/* 13 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Other"
                                        onChange={(e) => setOther(e.target.value)}
                                        value={other}
                                    />


                                </div>

                                {/* 4th Row */}
                                <div className="grid grid-cols-1 md:grid-cols-1 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 14 */}

                                    <div>

                                        <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file"
                                            onChange={(e) => setWordfile(e.target.files[0])}
                                        />
                                    </div>
                                </div>
                            </>
                        )}

                        {/* Plaintiffs Submitted Documents Section-C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={toggleplaintiffs}>
                            Plaintiffs Submitted Documents
                        </div>

                        {plaintiffs && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 15*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Plaintiffs Submitted Document"
                                            onChange={(e) =>
                                                setPlaintiffsSubmittedDocument(e.target.value)
                                            } // Corrected this line
                                            value={plaintiffsSubmittedDocument}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button type="button" onClick={handleAddPlaintiffsSubmittedDocument}>Add</Button>
                                    </div>
                                    {/* plaintiff's bullet points */}
                                    <ol className=" list-decimal px-5 ">
                                        {plaintiffsSubmittedDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndex === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValue}
                                                        onChange={(e) => setEditValue(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndex === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => saveEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>


                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 16*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Additional Plaintiff Documents"
                                            onChange={(e) =>
                                                setAdditionalPlaintiffDocument(e.target.value)
                                            }
                                            value={additionalPlaintiffDocument}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button onClick={handleAdditionalPlaintiffDocuments}>Add</Button>
                                    </div>

                                    {/* additional palntiffs bullet points */}
                                    <ol className=" list-decimal px-5 ">
                                        {additionalPlaintiffDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndexa === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValuea}
                                                        onChange={(e) => setEditValuea(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndexa === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => saveAEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startAEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>


                                </div>



                            </>
                        )}


                        {/* Defendant's Submitted Documents -C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={toggleDefendant}>
                            Defendant's Submitted Documents
                        </div>

                        {defendant && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 17*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Defendant's Submitted Documents"
                                            onChange={(e) =>
                                                setDefendantSubmittedDocumentmessage(e.target.value)}
                                            value={defendantSubmittedDocumentmessage}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button onClick={handleAdddefendantsSubmittedDocuments}>Add</Button>
                                    </div>
                                    {/* Show Bullet Points */}
                                    <ol className=" list-decimal px-5 ">
                                        {defendantsSubmittedDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndex1 === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValue1}
                                                        onChange={(e) => setEditValue1(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndex1 === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => savedEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startdEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>



                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 18*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Additional Defendant Documents"
                                            onChange={(e) =>
                                                setAdditionalDefendantDocument(e.target.value)}
                                            value={additionalDefendantDocument}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button onClick={handleAdditionalDefendantDocument}>Add</Button>
                                    </div>
                                    {/* Show Bullet Points */}
                                    <ol className=" list-decimal px-5 ">
                                        {additionalDefendantDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndexd === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValued}
                                                        onChange={(e) => setEditValued(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndexd === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => savedDEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startDEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>


                                </div>



                            </>
                        )}

                        {/* Witnesses-C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={togglewitness}>
                            Witnesses
                        </div>

                        {witness && (
                            <>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 19 */}

                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="List of Witnesses of Plaintiff"
                                        onChange={(e) => setNoOfWitnessesOfPlaintiff(e.target.value)} // Corrected this line
                                        value={noOfWitnessesOfPlaintiff}
                                    />



                                    {/* 19-A */}
                                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        value={WitnessesAvailablelist}
                                        onChange={(e) => setWitnessesAvailablelist(e.target.value)}
                                    >
                                        <option selected>Choose a option</option>
                                        <option value="Available">Available</option>
                                        <option value="Not Available">Not Available</option>
                                    </select>



                                    {/* 20 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="List of Witnesses of Defendant"
                                        onChange={(e) => setNoOfWitnessesOfDefendant(e.target.value)} // Corrected this line
                                        value={noOfWitnessesOfDefendant}
                                    />



                                    {/* 20-A */}
                                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        value={DefendentAvailablelist}
                                        onChange={(e) => setDefendentAvailablelist(e.target.value)}>
                                        <option selected>Choose a option</option>
                                        <option value="Available">Available</option>
                                        <option value="Not Available">Not Available</option>

                                    </select>


                                </div>

                                {/*  */}




                            </>
                        )}


                        {/* Application-C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={toggleapplication}>
                            Application
                        </div>

                        {application && (
                            <>

                                <div className="grid grid-cols-1 md:grid-cols-6 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 21 */}

                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Sr no"
                                        name="srNo"
                                        value={formData.srNo}
                                        onChange={handleInputChange}
                                    />

                                    {/* 22 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Application"
                                        name="application"
                                        value={formData.application}
                                        onChange={handleInputChange}
                                    />

                                    {/* 23 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Application date"
                                        name="applicationDate"
                                        value={formData.applicationDate}
                                        onChange={handleInputChange}
                                    />
                                    {/* 24 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Reply"
                                        name="reply"
                                        value={formData.reply}
                                        onChange={handleInputChange}
                                    />
                                    {/* 25 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Reply date"
                                        name="replyDate"
                                        value={formData.replyDate}
                                        onChange={handleInputChange}
                                    />


                                    <Button onClick={handleAddClick}> {editIndexap === -1 ? "Add" : "Update"}</Button>



                                </div>

                                {/* Table */}

                                <table className="border border-collapse border-black w-full mt-5 text-center">
                                    <thead>
                                        <tr>
                                            <th className="border border-black">srNo</th>
                                            <th className="border border-black">Application</th>
                                            <th className="border border-black">Application Date</th>
                                            <th className="border border-black">Reply</th>
                                            <th className="border border-black">Reply Date</th>
                                            <th className="border border-black">Edit</th>
                                            <th className="border border-black">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entries.map((entry, index) => (
                                            <tr key={index}>
                                                <td className="border border-black">{entry.srNo}</td>
                                                <td className="border border-black">
                                                    {entry.application}
                                                </td>
                                                <td className="border border-black">
                                                    {entry.applicationDate}
                                                </td>
                                                <td className="border border-black">{entry.reply}</td>
                                                <td className="border border-black">
                                                    {entry.replyDate}
                                                </td>
                                                {/* Display other fields here */}
                                                <td className="border border-black">
                                                    <button
                                                        className=" bg-red-500 px-3 py-1 rounded"
                                                        type="button"
                                                        onClick={() => handleEditClick(index)}
                                                    >
                                                        Edit
                                                    </button>
                                                </td>
                                                <td className="border border-black">
                                                    <button
                                                        className=" bg-red-500 px-3 py-1 rounded"
                                                        type="button"
                                                        onClick={() => handleDeleteClick(index)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>




                            </>
                        )}


                        {/* Dates */}
                        <div
                            className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer  shadow-lg  flex justify-center p-4 mt-4"
                            onClick={toggledates}
                        >
                            Dates
                            {/* arrow icon */}
                        </div>
                        {dates && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 26 */}

                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Filing of Suit"
                                        onChange={(e) => setFilingOfSuit(e.target.value)}
                                        value={filingOfSuit}
                                    />
                                    {/* 27 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="No.of Defendant"
                                        onChange={(e) => setNumberOfDefendants(e.target.value)}
                                        value={numberOfDefendants}
                                    />
                                    {/* 28 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="POA Filling date Plaintiff"
                                        onChange={(e) => setPoaFilingDatePlaintiff(e.target.value)}
                                        value={poaFilingDatePlaintiff}
                                    />
                                    {/* 29 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="POA Filing Date Defendant"
                                        onChange={(e) => setPoaFilingDateDefendant(e.target.value)}
                                        value={poaFilingDateDefendant}
                                    />
                                    {/* 30 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Defendant's Written Statement"
                                        onChange={(e) =>
                                            setDefendantsWrittenStatementDate(e.target.value)}
                                        value={defendantsWrittenStatementDate}
                                    />
                                    {/* 31 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Issues Framed"
                                        onChange={(e) => setIssuesFramedDate(e.target.value)}
                                        value={issuesFramedDate}
                                    />
                                    {/* 32 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Restraining Order"
                                        onChange={(e) => setRestrainingOrderDate(e.target.value)}
                                        value={restrainingOrderDate}
                                    />
                                    {/* 33 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Last Date of Hearing"
                                        onChange={(e) => setPrevHearing(e.target.value)}
                                        value={prevhearing}
                                    />
                                    {/* 34 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Next date of Hearing"
                                        onChange={(e) => setNextHearing(e.target.value)}
                                        value={nexthearing}
                                    />
                                </div>
                            </>
                        )}


                        <div className="flex justify-center mt-10 gap-2">
                            <button
                                className="bg-gray-400 hover:bg-gray-500 text-white font-semibold px-5 py-2"
                                type="submit"
                                disabled={isLoading}
                            // Assuming this triggers the update
                            >
                                {isLoading ? (
                                    <TailSpin color="#FFFFFF" height={20} width={20} />
                                ) : (
                                    'Update'
                                )}
                            </button>

                            <button onClick={requesthandler} type="button" className="bg-gray-400 hover:bg-gray-500 text-white font-semibold px-5 py-2">Request</button>
                        </div>


                    </form>
                </div>
            </div>
        </>
    );
};

export default Editcase;
