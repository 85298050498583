import React from "react";
import { Toaster } from "react-hot-toast";
import Sidenav from "./Components/Portal/Sidenav";
import Portalrouting from "./Components/Router/Webrouting";
import { useAuth } from "./Context/AuthContext";

const App = () => {
  const { authData } = useAuth();
  return (
    <>
      {authData ? <Sidenav /> : <Portalrouting />}

      <Toaster />
    </>
  );
};

export default App;
